<template>
  <div class="user-details">
    <div class="user-details-header"></div>
    <div class="user-details-content p-2 d-flex justify-content-around flex-fill">
      <div class="left">
        <h5>Account Details:</h5>
        <div class="details">
          <div class="item" v-for="field in fieldsToShow" :key="field.key">
            <div v-if="user[field.key]" class="field-label d-flex align-items-center">
              <h6>{{ field.label }}:</h6>
              <p class="ml-2">{{ user[field.key] }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <h5>Notification Settings:</h5>
        <notification-settings :email="user && user.email" class="mt2" display-type="noDropdown"></notification-settings>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationSettings from "@/components/ui/NotificationSettings"
import { getDecodedUserData } from "@/utils/TokenTools"
export default {
  name: "UserDetails",
  components: {
    NotificationSettings
  },
  data() {
    return {
      fieldsToShow: [
        {
          key: "username",
          label: "Name"
        },
        {
          key: "email",
          label: "Email"
        },
        {
          key: "phone",
          label: "Phone"
        },
        {
          key: "company",
          label: "Company"
        }
      ]
    }
  },
  computed: {
    user() {
      return getDecodedUserData()
    }
  }
}
</script>

<style scoped>
.base-modal {
  min-width: 500;
}
p,
h6 {
  margin: 0;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
}
.item {
  padding: 5px 0px 5px 0px;
}
</style>
